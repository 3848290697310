.benefit {
  position: relative;
  padding: 90px 0 90px;
  background-image: url(../img/benefit/bg.png), var(--linear-gold);
  background-repeat: no-repeat;
  background-position: center bottom;

  @media (--mobile) {
    padding: 85px 0 70px;
    background-image: url(../img/benefit/bg-mob.png), var(--linear-gold);
    background-size: contain;
  }

  #benefits {
    position: absolute;
    top: -83.5px;
  }

  &__container {
    max-width: var(--main-max-width);

    @media screen and (min-width: 601px) and (max-width: 1049px) {
      width: calc(100% - 90px);
      margin: 0 45px;
    }

    @media screen and (min-width: 1050px) and (max-width: 1279px) {
      padding: 0 70px;
    }

    @media (--desktop-m) {
      width: 100%;
      margin: 0 auto;
    }
  }

  &__title {
    margin: 0 0 45px;
    font-size: 48px;
    line-height: 41px;
    color: var(--color-dark);

    @media (--mobile) {
      font-size: 35px;
      line-height: 1em;
      margin: 0 30px 20px;
    }
  }

  &__switch {
    display: flex;
    margin-bottom: 75px;

    @media (--mobile) {
      margin: 0 0 30px;
      overflow-y: auto;
    }

    &-container {
      display: flex;
      width: max-content;
      border-radius: 40px;
      background-color: var(--color-white);

      @media (--mobile) {
        position: relative;
        margin: 0 30px;

        &:after {
          position: absolute;
          content: "";
          width: 30px;
          height: 1px;
          right: -30px;
        }
      }

      @media (--tablet) {
        width: 100%;
      }
    }

    &-item {
      flex: 1;
      height: 70px;
      font-weight: bold;
      font-size: 16px;
      line-height: 1.4em;
      background-color: transparent;
      color: var(--color-dark);
      border: none;
      border-radius: 40px;
      transition: all 0.2s ease-out;

      @media (--mobile) {
        min-width: 190px;
        height: 60px;
      }

      &[disabled] {
        @media (--mobile) {
          display: none;
        }

        pointer-events: none;
        opacity: 0.4;
        &:hover {
          background-color: transparent;
        }
      }

      &:hover {
        background-color: var(--color-gray-light);
      }

      &.active {
        color: var(--color-gray-light);
        background-color: var(--color-dark);
        box-shadow: 0px 8px 5px -6px rgba(115, 34, 8, 0.2),
          0px 22px 24px -17px rgba(110, 46, 0, 0.4);
      }
    }

    &-content {
      display: none;
      justify-content: space-between;
      flex-wrap: wrap;

      &.active {
        display: flex;
      }

      @media (--mobile) {
        margin: 0 35px;
      }
    }

    &-info {
      max-width: 300px;
      width: 100%;
      margin-bottom: 70px;

      &:nth-child(4),
      &:nth-child(5) {
        margin-bottom: 0;

        @media (--mobile) {
          margin-bottom: 40px;
        }

        @media (--tablet) {
          margin-bottom: 40px;
        }
      }

      &:nth-child(6) {
        margin-bottom: 0;

        @media (--tablet) {
          margin-bottom: 40px;
        }
      }

      @media (--mobile) {
        margin-bottom: 40px;
      }

      strong,
      p {
        font-size: 18px;
        line-height: 1.4em;
        color: var(--color-dark);
      }

      strong {
        display: block;
        font-weight: bold;
        margin-bottom: 15px;
      }

      p {
        font-family: "Roboto";
        opacity: 0.8;
      }
    }
  }
}

.menu {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  transform: translateX(100%) translateY(0);
  transition: transform 0.4s ease-out;
  background-color: var(--color-black);
  background-image: url(../img/menu/bg.png);
  background-repeat: no-repeat;
  background-position: right top;
  z-index: 3;

  &.active {
    transform: translateX(0) translateY(0);
  }

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 2;
    padding: 0 0 90px;
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  &__navigation {
    display: flex;
    flex-direction: column;
    margin: 0 15px;
    padding-top: 25%;
    height: 100%;

    a {
      display: block;
      font-weight: 500;
      font-size: 36px;
      line-height: 1.65em;
      color: var(--color-white);
      text-decoration: none;

      &.bordered {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: auto;
        padding: 20px 30px;
        font-size: 18px;
        height: 70px;
        line-height: 1;
        border: 2px solid var(--color-yellow);
        border-radius: 40px;
        background-color: transparent;
      }

      &.social {
        margin: 20px 0 0 0;
        font-size: 18px;
      }
    }
  }
}

.how-it-works {
  position: relative;
  padding: 100px 0 0;
  width: 100%;
  background-color: var(--color-dark);
  border-radius: 70px 70px 0 0;
  overflow: hidden;

  @media (--mobile) {
    padding: 60px 0 0;
    border-radius: 50px 50px 0 0;
  }

  @media (--desktop-large) {
    background-size: 40%;
  }

  #how-it-works {
    position: absolute;
    top: -110px;

    @media (--mobile) {
      top: -80px;
    }
  }

  &__register {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 100%;
    margin: 0;
    padding: 0 40px;
    font-weight: 700;
    font-size: 16px;
    color: var(--color-dark);
    background-color: var(--color-white);
    text-decoration: none;
    border-radius: 54px;
    transition: all 0.1s ease-out;
    cursor: pointer;

    @media screen and (max-width: 899px) {
      margin-top: 35px;
    }

    @media (--tablet-l) {
      margin-top: 45px;
    }

    &:hover {
      background-color: var(--color-yellow);
    }
  }

  &__background {
    position: absolute;
    right: 0;
    z-index: 0;

    @media (--tablet) {
      top: 29%;
    }

    @media (--tablet-m) {
      top: 29%;
    }

    @media (--tablet-l) {
      top: 29%;
    }

    @media (--desktop-m) {
      top: 35%;
    }

    @media (--desktop-large) {
      top: 35%;
    }

    &--mobile {
      display: none;

      @media (--mobile) {
        display: block;
        top: 29%;
        right: -20%;
        max-width: 430px;
        width: 100%;
        max-height: 410px;
      }
    }

    &--desktop {
      @media (--mobile) {
        display: none;
      }
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    max-width: 980px;

    @media (--mobile) {
      margin: 0 15px 70px;
    }

    @media (--tablet) {
      margin: 0 35px 100px;
    }

    @media (--desktop-m) {
      margin: 0 auto 100px;
    }

    &--process {
      max-width: 1010px;
    }

    &--points {
      flex-direction: column;

      @media (--tablet-l) {
        flex-direction: row;
      }
    }
  }

  &__title {
    max-width: var(--main-max-width);
    color: var(--color-white);

    @media (--mobile) {
      font-size: 36px;
      line-height: 1em;
      margin: 0 15px;
    }

    @media (--tablet) {
      font-size: 48px;
      line-height: 50px;
    }

    @media screen and (min-width: 601px) and (max-width: 1049px) {
      margin: 0 30px;
    }

    @media (--desktop-large) {
      width: 100%;
    }

    &-container {
      max-width: var(--main-max-width);

      @media screen and (max-width: 1049px) {
        margin-bottom: 45px;
      }

      @media screen and (min-width: 1050px) and (max-width: 1279px) {
        margin: 0 0 45px 7vw;
      }

      @media (--desktop-large) {
        margin: 0 auto 45px;
      }
    }

    &--points {
      margin: 0 0 80px;

      @media (--mobile) {
        margin: 0 0 65px;
      }
    }

    &--process {
      margin: 0 0 45px;

      @media (--mobile) {
        margin: 0 15px 30px;
      }
    }

    mark {
      position: relative;
      background-color: transparent;
      color: inherit;

      &:after {
        position: absolute;
        top: 70%;
        left: 0;
        content: url(../img/how-it-works/underline.svg);
      }
    }
  }

  &__points {
    position: relative;
    z-index: 1;

    @media (--mobile) {
      margin: 0 15px 60px;
    }

    @media (--tablet) {
      margin-bottom: 60px;
    }

    @media (--tablet-l) {
      max-width: 490px;
      margin: 40px 0 0;
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0 0 65px;

      @media (--tablet) {
        align-items: center;
        flex-direction: row;
        margin: 0 0 70px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      figure {
        display: flex;
        justify-content: center;

        @media (--mobile) {
          margin-bottom: 30px;
        }

        @media (--tablet) {
          max-width: 85px;
          width: 100%;
          margin-right: 45px;
          margin-bottom: 0;
        }
      }

      p {
        font-size: 24px;
        line-height: 1.45em;
        color: var(--color-white);
      }
    }
  }

  &__process {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    @media (--tablet-l) {
      max-width: 490px;
      width: 100%;
    }

    @media (--desktop-m) {
      max-width: 610px;
    }

    &-message {
      display: none;
      flex-direction: column;
      align-items: center;
      width: 298px;
      height: 557px;
      padding: 15px 16px;
      background-image: url("../img/how-it-works/phone.svg");
      background-repeat: no-repeat;
      @media (--tablet-l) {
        display: flex;
      }

      & > div {
        overflow: hidden;
      }

      img {
        margin-bottom: 8.5px;

        @keyframes firstImageAnimation {
          0% {
            transform: translateY(-130%);
            opacity: 0;
          }
          10% {
            transform: translateY(0);
            opacity: 1;
          }
          20% {
            transform: translateY(0);
          }
          30% {
            transform: translateY(115%);
          }
          90% {
            opacity: 1;
          }
          95% {
            opacity: 0;
          }
          100% {
            transform: translateY(115%);
            opacity: 0;
          }
        }

        @keyframes secondImageAnimation {
          0% {
            transform: translateY(-240%);
            opacity: 0;
          }
          20% {
            transform: translateY(-240%);
            opacity: 0;
          }
          30% {
            transform: translateY(-115%);
            opacity: 1;
          }
          90% {
            opacity: 1;
          }
          95% {
            opacity: 0;
          }
          100% {
            transform: translateY(-115%);
            opacity: 0;
          }
        }

        &:first-child {
          position: relative;
          z-index: 1;
          animation: firstImageAnimation ease-out 5s infinite;
        }

        &:last-child {
          animation: secondImageAnimation ease-out 5s infinite;
          opacity: 0;
        }
      }
    }

    &-text {
      margin-bottom: 60px;
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      line-height: 30px;
      color: var(--color-white);

      @media (--mobile) {
        margin: 0 15px 45px;
      }
    }

    &-form {
      display: flex;
      flex-direction: column;
      max-width: 380px;
      width: 100%;
      margin: 0 auto;

      @media (--tablet-l) {
        margin: 0;
      }

      &-input {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 80px;
        margin-bottom: 40px;
        padding: 0 20px;
        border: 2px solid #e5e8f1;
        border-radius: 15px;

        &.error {
          border-color: #eb2d44;

          .how-it-works__process-form-input-error {
            display: block;
          }
        }

        &.valid {
          .how-it-works__process-form-input-valid {
            display: block;
          }
        }

        &-error,
        &-valid {
          display: none;
          width: 20px;
          height: 20px;
        }

        label {
          position: absolute;
          top: -12px;
          left: 15px;
          padding: 2px 5px;
          width: max-content;
          font-family: Roboto;
          font-size: 14px;
          line-height: 16px;
          color: #e5e8f1;
          background-color: var(--color-dark);
        }

        input {
          flex: 1;
          padding: 30px 0;
          font-family: Roboto;
          font-size: 18px;
          line-height: 20px;
          color: #e5e8f1;
          background-color: transparent;
          border: none;

          &::placeholder {
            color: #9ea5b8;
          }
        }
      }
    }
  }
  &__open-menu,
  &__process-form-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    font-family: Codec Pro;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.4em;
    color: var(--color-white);
    background-color: transparent;
    border: 2px solid var(--color-yellow);
    border-radius: 40px;
    transition: all 0.1s ease-out;
    user-select: none;
    text-decoration: none;

    &:hover {
      color: var(--color-dark);
      background-color: #fcd561;
    }

    &:disabled {
      background-color: var(--color-yellow);
      pointer-events: none;
    }
  }

  &__rate {
    position: relative;
    background-color: var(--color-black);
    border-radius: 70px 70px 0 0;

    @media screen and (max-width: 1049px) {
      padding: 55px 15px 70px;
    }

    @media (--desktop-m) {
      padding: 90px 70px 120px;
    }

    #price {
      position: absolute;
      top: -83.5px;
    }

    &-discount {
      margin-top: 5px;
      font-size: 24px;
      line-height: 21px;
      color: var(--color-gray-dark);
      text-decoration: line-through;
    }

    &-price {
      line-height: 1 !important;

      &-container {
        display: flex;
        flex-direction: column;
        height: 112px;

        @media screen and (min-width: 600px) and (max-width: 1049px) {
          justify-content: center;
          height: auto;
          margin-right: 20px;
        }
      }

      var {
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
        line-height: 55px;
        color: var(--color-dark);

        @media (--tablet) {
          text-align: center;
        }

        @media screen and (max-width: 899px) {
          font-size: 52px;
        }

        @media (--tablet-l) {
          font-size: 56px;
        }

        @media (--desktop-large) {
          text-align: left;
        }

        abbr {
          margin-left: 5px;
          font-size: 24px;
          line-height: 31px;
          white-space: nowrap;

          @media (--tablet) {
            display: block;
            margin-top: 10px;
          }

          @media (--tablet-m) {
            display: inline;
            margin-top: 0;
          }

          @media (--desktop-m) {
            display: block;
            margin-top: 10px;
          }

          @media (--desktop-large) {
            display: inline;
            margin-top: 0;
          }
        }
      }
    }

    &-container {
      display: flex;

      @media screen and (max-width: 1049px) {
        flex-direction: column;
      }

      @media (--desktop-m) {
        justify-content: center;
      }

      @media screen and (min-width: 1050px) and (max-width: 1279px) {
        flex-wrap: wrap;
      }
    }

    &-item {
      display: flex;
      border-radius: 70px;
      cursor: pointer;

      &:last-child {
        @media (--mobile) {
          margin-bottom: 0;
        }
      }

      @media screen and (max-width: 1049px) {
        margin-bottom: 10px;
        flex: 1;
      }

      @media screen and (min-width: 601px) and (max-width: 1049px) {
        justify-content: space-between;
      }

      @media screen and (max-width: 1279px) {
        padding: 45px 35px 35px;
      }

      @media (--tablet-l) {
        padding: 50px;
      }

      @media screen and (max-width: 599px) {
        flex-direction: column;
      }

      @media (--desktop-m) {
        max-width: 420px;
        flex-direction: column;
        margin-right: 20px;
      }

      @media screen and (min-width: 1050px) and (max-width: 1279px) {
        &:nth-child(2) {
          margin-right: 0;
        }
      }

      &:last-child {
        @media (--desktop-m) {
          margin-right: 0;
        }
      }

      &-info {
        display: flex;
        flex-direction: column;
        flex: 1;

        @media screen and (min-width: 601px) and (max-width: 1049px) {
          max-width: 50%;
          min-width: 50%;
        }

        @media (--desktop-m) {
          width: 100%;
        }

        p,
        ul {
          font-family: "Roboto";
        }
      }

      strong {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 18px;
        line-height: 1.4em;
        color: var(--color-dark);
      }

      p {
        font-size: 16px;
        line-height: 1.5em;
        color: var(--color-black-light);
      }

      ul {
        margin-top: 10px;
        padding-left: 10px;
        line-height: 1.5;
        color: var(--color-black-light);

        @media (--desktop-m) {
          margin-bottom: auto;
        }

        li {
          list-style-type: disc;
          margin: 5px 0;

          &.highlighted {
            font-weight: bold;
          }
        }
      }

      &--payment {
        background-color: var(--color-dark);

        strong {
          color: var(--color-white);
        }

        p {
          color: var(--color-gray);
        }

        ul {
          color: var(--color-gray);
        }

        .how-it-works__rate-price var {
          color: var(--color-white);
        }

        @media screen and (min-width: 1050px) and (max-width: 1279px) {
          margin-top: 20px;
        }
      }

      &--orders {
        background: var(--linear-gold);
      }

      &--menu {
        background: var(--color-gray-lighter);
      }
    }
  }

  &__discount {
    font-size: 20px;
    line-height: 30px;
    color: var(--color-gray);
    max-width: 560px;

    @media (--mobile) {
      margin: 45px 15px;
    }

    @media screen and (min-width: 601px) and (max-width: 1049px) {
      margin: 45px 30px;
    }

    @media (--desktop-m) {
      margin-top: 25px;
    }
  }
}

.blog {
  position: relative;
  padding: 90px 0 50px;
  background-color: var(--color-black);
  background-image: url(../img/blog/bg.png);
  background-repeat: no-repeat;
  background-position: bottom;

  h1 {
    width: auto;
  }

  @media (--mobile) {
    padding: 80px 0 60px;
    background-image: url(../img/blog/bg-mob-1.png),
      url(../img/blog/bg-mob-2.png);
    background-position: right top, left 120%;
  }

  &-main {
    .blog {
      padding: 181px 0 50px;

      @media (--mobile) {
        padding: 171px 0 60px;
      }
    }
  }

  #blog {
    position: absolute;
    top: -110px;

    @media (--mobile) {
      top: -80px;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-width: var(--main-max-width);

    @media (--tablet-l) {
      flex-wrap: nowrap;
      margin: 0 auto;
    }

    @media (--desktop-m) {
      flex-wrap: wrap;
      flex-direction: row;
    }

    @media (--desktop-large) {
      width: 100%;
    }
  }

  &__title {
    margin-right: 20px;
    font-size: 48px;
    line-height: 1.05em;
    color: var(--color-white);

    @media (--mobile) {
      margin: 0 30px;
      font-size: 36px;
      line-height: 1em;
    }

    @media screen and (min-width: 601px) and (max-width: 1049px) {
      margin: 0 45px;
    }

    @media (--tablet-l) {
      margin: 0 75px;
    }
  }

  &__items {
    display: flex;
    flex-direction: row;
    margin-top: -30px;

    @media (--mobile) {
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: center;
      width: calc(100% - 30px);
      margin: 50px 15px 0;
    }

    @media (--tablet) {
      flex-wrap: wrap;
      margin: 50px 30px;
      /* flex-direction: column;
      width: calc(100% - 30px);
      margin: 50px 15px 0;  */
    }

    @media (--tablet-m) {
      flex-wrap: wrap;
      max-width: 710px;
      width: 100%;
      margin: 50px auto 0;
    }

    @media (--tablet-l) {
      max-width: 770px;
    }

    @media (--desktop-m) {
      margin: 0;
      margin-top: -30px;
    }
  }

  &__item {
    position: relative;
    top: 0px;
    display: flex;
    flex-direction: column;
    width: calc(50% - 30px);
    height: 380px;
    box-sizing: border-box;
    margin: 10px;
    padding: 30px 50px 50px 50px;
    background-color: var(--color-dark);
    border-radius: 70px;
    text-decoration: none;
    overflow: hidden;
    transition: background-color 0.1s ease-out;

    &-title {
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 18px;
      line-height: 1.4em;
      color: var(--color-white);
      overflow: hidden;
    }

    span {
      display: flex;
      font-size: 14px;
      line-height: 1.8em;
      color: var(--color-gray);
      opacity: 0.5;

      &:before {
        content: url(../img/blog/eye.svg);
        margin-right: 10px;
        margin-left: 2px;
      }
    }

    figure {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 200px;
      margin-bottom: 20px;

      img {
        width: 80%;
        max-height: 90%;
        object-fit: contain;
      }
    }

    &:nth-child(2n + 1) {
      top: -100px;
    }

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      height: 480px;

      figure {
        height: 275px;

        img {
          width: 100%;
        }
      }
    }

    @media (--mobile) {
      width: calc(100% -20px);
      padding: 20px 40px 40px 40px;

      &:nth-child(2n + 1) {
        top: 0px;
      }

      &:nth-child(2) {
        height: 380px;

        figure {
          height: 200px;

          img {
            width: 70%;
          }
        }
      }
    }

    &:hover {
      background-color: var(--color-yellow);
    }
  }

  &__link {
    height: fit-content;
    margin: 120px auto 80px;
    font-size: 18px;
    line-height: 25px;
    color: var(--color-gray);
    text-decoration: none;
    border-bottom: 2px solid var(--color-gray);
    transition: all 0.1s ease-out;

    &:hover {
      color: var(--color-white);
      border-color: var(--color-white);
    }
  }
}

.offer {
  position: relative;
  display: flex;

  @media screen and (max-width: 767px) {
    padding: 40px 0 80px;
  }

  @media screen and (min-width: 768px) and (max-width: 1049px) {
    padding: 100px 0 80px;
  }

  @media (--desktop-m) {
    padding: 100px 0 140px;
  }

  &__arrows {
    position: absolute;
    bottom: 80px;
    display: flex;
  }

  &__arrow {
    cursor: pointer;

    svg {
      path {
        transition: stroke 0.2s ease-out;
      }
    }

    &:hover {
      svg {
        path {
          stroke: var(--color-dark);
        }
      }
    }

    &--prev {
      margin-right: 30px;
    }
  }

  &__item {
    position: relative;
    display: flex;
    margin-bottom: 80px;
    margin-right: 120px;
    background-color: var(--color-gray-light);
    cursor: pointer;

    @media screen and (max-width: 1049px) {
      flex-direction: column;
      padding-left: 0;
      border-radius: 50px;
    }

    @media (--tablet) {
    }

    @media (--desktop-m) {
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      border-radius: 70px;
      margin-bottom: 0;
    }

    @media screen and (min-width: 1050px) and (max-width: 1279px) {
      padding-left: 60px;
    }

    @media (--desktop-large) {
      padding-left: 80px;
    }

    &:last-child {
      &:after {
        content: "";
        display: block;
        position: absolute;
        right: -150px;
        width: 150px;
        height: 1px;
      }
    }
  }

  &__info {
    @media screen and (max-width: 1049px) {
      order: 1;
      padding: 0 23px 40px;
    }

    @media (--tablet) {
      order: 1;
    }

    @media screen and (min-width: 1050px) and (max-width: 1279px) {
      width: 100%;
      max-width: 485px;
    }

    @media (--desktop-large) {
      max-width: 525px;
      width: 100%;
    }

    h2 {
      color: var(--color-dark);

      @media screen and (max-width: 1279px) {
        margin-bottom: 15px;
        font-size: 32px;
        line-height: 110%;
      }

      @media (--desktop-large) {
        margin-bottom: 20px;
        font-size: 40px;
        line-height: 110%;
      }
    }

    p,
    ol {
      font-family: "Roboto";
      font-size: 18px;
      line-height: 25px;
      color: #58585c;
    }

    ol {
      list-style: disc;
      padding-left: 25px;

      li {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__image {
    display: flex;
    justify-content: flex-end;

    overflow: hidden;

    @media (--mobile) {
      justify-content: center;
      align-items: center;
      overflow: hidden;
      width: 100%;
      height: 300px;
    }

    @media (--tablet) {
      height: 400px;
      align-items: center;
      justify-content: center;
    }

    @media (--desktop-m) {
      height: 420px;
      margin: 0 auto;
    }

    img {
      mix-blend-mode: darken;
      object-fit: contain;
      width: 80%;

      @media screen and (max-width: 1279px) {
        max-width: 300px;
        max-height: 100%;
      }

      @media (--desktop-m) {
        max-width: 450px;
      }
    }

    &--card {
      img {
        width: 70%;
      }
    }
  }

  .swiper-pagination-progressbar {
    top: inherit !important;
    left: calc(50% - 190px) !important;
    bottom: 0;
    width: 380px !important;
    height: 1px !important;
    background: #e8e8e8 !important;

    @media (--mobile) {
      width: 170px !important;
      left: calc(50% - 85px) !important;
    }
  }

  .swiper-slide {
    @media (--mobile) {
      height: auto;
    }

    @media (--tablet) {
      height: auto;
    }

    @media (--desktop-large) {
      max-width: 1080px;
      margin: 0 auto;
    }

    .offer__info {
      transition: opacity 0.2s ease-out;
      opacity: 0;
    }

    .offer__image {
      img {
        transition: opacity 0.2s ease-out;
        opacity: 0;
      }
    }

    &-active {
      .offer__info {
        opacity: 1;
      }

      .offer__image {
        img {
          opacity: 1;
        }
      }
    }
  }

  .swiper-pagination-progressbar-fill {
    background: var(--color-dark) !important;
  }
}

.client-stat {
  background-image: url("../img/client-stat.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media screen and (max-width: 1049px) {
    padding: 55px 15px 75px;
  }

  @media (--desktop-m) {
    padding: 70px;
  }
  &__title {
    color: var(--color-white);

    @media (--mobile) {
      margin-left: 15px;
      font-size: 36px;
      line-height: 110%;
    }

    @media (--tablet) {
      font-size: 48px;
      line-height: 110%;
    }

    @media screen and (min-width: 601px) and (max-width: 1049px) {
      margin-left: 30px;
    }

    @media screen and (min-width: 1050px) and (max-width: 1279px) {
      margin-left: 7vw;
    }

    @media screen and (max-width: 1279px) {
      margin-bottom: 30px;
    }

    @media (--desktop-large) {
      max-width: var(--main-max-width);
      margin: 0 auto 45px;
    }
  }

  &__items {
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 45px;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(12.5px);
      /* Note: backdrop-filter has minimal browser support */

      border-radius: 50px;
    }

    @media (--tablet-m) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    @media (--desktop-xlarge) {
      max-width: 1300px;
      margin: 0 auto;
    }
  }

  &__item {
    display: flex;
    flex: 1;
    flex-direction: column;

    @media screen and (max-width: 767px) {
      align-items: center;

      &:not(:last-child)::after {
        content: "";
        width: 100px;
        height: 1px;
        margin: 35px 0 40px;
        opacity: 0.4;
        background: rgba(255, 255, 255, 0.4);
      }
    }

    @media (--tablet-m) {
      padding: 45px 25px;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(15px);
      margin-right: 20px;
      border-radius: 30px;

      &:last-child {
        margin-right: 0;
      }
    }

    @media (--desktop-large) {
      padding: 45px 50px 40px;
      border-radius: 50px;
    }
  }

  &__value {
    display: inline-flex;
    white-space: nowrap;
    margin-bottom: 10px;
    color: var(--color-white);

    @media screen and (max-width: 1279px) {
      font-size: 40px;
      line-height: 34px;
    }

    @media (--desktop-large) {
      font-size: 48px;
      line-height: 41px;
    }
  }

  &__description {
    display: inline-flex;
    font-size: 18px;
    line-height: 25px;
    color: #bfc1c9;
  }
}

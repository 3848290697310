.iframe-container {
    width: 100vw;
    height: calc(100vh - 110px);
    text-align: center;

    @media (--mobile) {
        height: calc(100vh - 80px);
    }
}

.iframe-container iframe {
    border: none;
    max-width: 800px; 
    width: 100%; 
    height: 100%;
}
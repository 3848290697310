.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: transparent;
  border-bottom: 1px solid transparent;
  transition: all 0.1s ease-out;
  z-index: 2;

  &.scrolled {
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-gray-border);

    .header__link {
      &:hover {
        color: var(--color-yellow);
      }
    }
  }

  @media screen and (max-width: 1279px) {
    padding: 15px;
  }

  @media (--tablet-xs) {
  }

  @media (--desktop-large) {
    padding: 15px 70px;
  }

  &__navigation {
    @media (--mobile) {
      display: none;
    }

    @media (--tablet) {
      display: none;
    }

    @media (--tablet-xs) {
      display: flex;
      align-items: center;
    }
  }

  &__link {
    display: inline-flex;
    margin-right: 25px;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    color: var(--color-dark);
    text-decoration: none;
    top: 110px;
    transition: color 0.1s ease-out;

    &:hover {
      color: var(--color-white);
    }
  }

  &__btn {
    align-items: center;
    height: 50px;
    padding: 0 40px;
    margin-right: 0;
    font-weight: 700;
    font-size: 16px;
    border-radius: 54px;
    text-decoration: none;
    color: var(--color-dark);
    background-color: var(--color-white);
    border: 2px solid var(--color-yellow);
    transition: background-color 0.1s ease-out;
    cursor: pointer;

    &:hover {
      background-color: var(--color-yellow);
    }

    @media screen and (max-width: 699px) {
      display: none;
    }

    @media (--tablet-xs) {
      display: flex;
    }
  }

  &__burger {
    position: relative;
    display: none;
    height: 45px;
    width: 45px;
    z-index: 1;
    outline: 0;
    padding: 0;
    border: 0;
    background: none;
    transition: 0.4s transform linear;

    @media (--mobile) {
      display: block;
    }

    @media (--tablet) {
      display: block;
    }

    @media (--tablet-xs) {
      display: none;
    }

    &-line {
      &,
      &::before {
        position: absolute;
        background: #3e3e3e;
        width: 25px;
        height: 2px;
        content: "";
        top: 55%;
        left: 0;
      }

      & {
        margin-top: 0;
      }

      &::before {
        top: -6px;
      }

      &::before {
        transform: rotate(0deg);
      }
    }

    &-line {
      left: 10px;
    }
  }
}

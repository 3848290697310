.apply-popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transform: translateX(100%) translateY(0);
  transition: opacity .15s ease-out;
  opacity: 0;
  background-color: rgba(224, 224, 224, 0.5);
  backdrop-filter: blur(15px);
  z-index: 3;

  &.active {
    transform: translateX(0) translateY(0);
    opacity: 1;
    touch-action: none;
    -ms-touch-action: none;
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 580px;
    width: 100%;
    padding: 70px 100px;
    background-color: var(--color-white);
    box-shadow: 0px 1px 4px rgba(13, 23, 50, 0.2);
    border-radius: 30px;
    z-index: 2;

    @media (--mobile) {
      max-width: 380px;
      width: calc(100% - 30px);
      margin: 0 15px;
      padding: 50px 30px;
    }
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    cursor: pointer;

    svg {
      path {
        transition: stroke 0.1s ease-out;
      }
    }

    &:hover {
      svg {
        path {
          stroke: var(--color-dark);
        }
      }
    }
  }

  &__title {
    margin-bottom: 35px;
    font-family: Codec Pro;
    font-size: 36px;
    line-height: 1em;
    color: var(--color-dark);

    @media (--mobile) {
      font-size: 28px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__input {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 70px;
    margin-bottom: 20px;
    padding: 0 35px;
    border: 1px solid var(--color-gray-border-input);
    border-radius: 35px;

    &.error {
      border-color: #EB2D44;

      .apply-popup__input-error {
        display: block;
      }
    }

    &.valid {
      .apply-popup__input-valid {
        display: block;
      }
    }

    label {
      position: absolute;
      top: -12px;
      left: 30px;
      padding: 2px 5px;
      width: max-content;
      font-family: Roboto;
      font-size: 14px;
      line-height: 16px;
      color: var(--color-dark);
      background-color: var(--color-white);
    }

    input {
      flex: 1;
      font-family: Roboto;
      font-size: 14px;
      line-height: 16px;
      color: #0D1732;
      border: none;

      &::placeholder {
        color: #9EA5B8;
      }
    }
  }

  &__footer {
    margin-top: 30px;
    text-align: center;

    a {
      margin: 0 20px 20px 0;
      display: inline-block;
      text-decoration: none;
      color: inherit;
      border-bottom: solid 1px transparent;
      transition: all 0.1s ease-out;
      white-space: nowrap;

      &:hover {
        border-bottom: solid 1px var(--color-dark);
      }
    }
  }

  &__input-error,
  &__input-valid {
    display: none;
    width: 20px;
    height: 20px;
  }

  &__form-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.4em;
    color: var(--color-dark);
    background-color: var(--color-white);
    border: 2px solid var(--color-yellow);
    border-radius: 54px;
    transition: background-color 0.2s ease-out;
    user-select: none;

    &:hover {
      background-color: var(--color-yellow);
    }

    &:disabled {
      background-color: var(--color-yellow);
      pointer-events: none;
    }

  }
}
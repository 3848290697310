@font-face {
	font-family: 'Codec Pro';
	src: url('../fonts/Codec-Pro-Regular.woff') format('woff'),
			 url('../fonts/Codec-Pro-Regular.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Bold.woff') format('woff'),
			 url('../fonts/Roboto-Bold.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Regular.woff') format('woff'),
			 url('../fonts/Roboto-Regular.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
* {
	margin: 0;
	padding: 0;
	font: inherit;
	box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
	display: block;
}

audio,
canvas,
progress,
video {
	display: inline-block;
	vertical-align: baseline;
}

audio:not([controls]) {
	display: none;
	height: 0;
}

[hidden],
template {
	display: none;
}

html {
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-focus-ring-color: rgba(255,255,255,0);
	cursor: default;
}

body {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

a {
	background: transparent;
}

*:focus,
*:active,
*:hover {
	outline: none;
}

hr {
	box-sizing: content-box;
	height: 0;
}

ol,
ul {
	list-style: none;
}

pre {
	tab-size: 4;
	white-space: pre-wrap;
}

q {
	quotes: '\201C' '\201D' '\2018' '\2019';
}

img {
	border: none;
}

svg:not(:root) {
	overflow: hidden;
}

button,
input {
	line-height: normal;
}

button,
select {
	text-transform: none;
}

button {
	overflow: visible;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
	-webkit-appearance: button;
	cursor: pointer;
}

button[disabled],
html input[disabled] {
	cursor: default;
}

input[type='checkbox'],
input[type='radio'] {
	box-sizing: border-box;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	height: auto;
}

input[type='search'] {
	-webkit-appearance: textfield;
	box-sizing: content-box;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
	-webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: none;
	padding: 0;
}

textarea {
	overflow: auto;
	vertical-align: top;
}

button,
input,
select[multiple],
textarea {
	background-image: none;
}

input,
select,
textarea {
	border-radius: 0;
	box-shadow: none;
}

input,
textarea {
	resize: none;
	user-select: text;
}

[placeholder]:focus::placeholder {
	color: transparent;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}
.reviews {
  position: relative;
  z-index: 1;
  padding-bottom: 50px;
  background-color: var(--color-black);

  &__container {
    max-width: var(--main-max-width);

    @media screen and (min-width: 601px) and (max-width: 1049px) {
      width: calc(100% - 90px);
      margin: 0 45px;
    }

    @media screen and (min-width: 1050px) and (max-width: 1279px) {
      padding: 0 70px 50px;
    }

    @media (--desktop-m) {
      width: 100%;
      margin: 0 auto;
    }

    @media (--desktop-large) {
      padding: 0 0 50px;
    }
  }

  &__title {
    margin: 0 0 45px;
    font-size: 48px;
    line-height: 41px;
    color: var(--color-white);

    @media (--mobile) {
      font-size: 35px;
      line-height: 1em;
      margin: 0 30px 20px;
    }
  }

  figure {
    margin-right: 30px;

    img {
      width: 90px;
      height: 90px;
    }
  }

  &__item {
    display: flex;
    padding-top: 35px;
    padding-bottom: 50px;
    border-bottom: 2px solid var(--color-dark);

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    @media (--mobile) {
      flex-wrap: wrap;
      margin: 0 30px;
    }

    @media (--tablet) {
      flex-wrap: wrap;
    }

    @media (--tablet-m) {
      flex-wrap: nowrap;
    }
  }

  &__person {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    margin-top: 20px;

    @media (--mobile) {
      margin-right: 0;
    }
  }

  &__name {
    font-size: 18px;
    line-height: 1.4em;
    color: var(--color-white);
  }

  &__place {
    font-family: "Roboto";
    font-size: 16px;
    line-height: 1.5em;
    color: var(--color-gray);
  }

  &__feedback {
    margin-left: auto;
    margin-top: 10px;
    font-family: Roboto;
    font-size: 20px;
    line-height: 1.5em;
    color: var(--color-gray);

    @media (--mobile) {
      margin-top: 30px;
    }

    @media (--tablet-m) {
      max-width: 430px;
    }

    @media (--tablet-l) {
      max-width: 560px;
    }
  }
}

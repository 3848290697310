.get-orders {
  &__container {
    display: flex;
    justify-content: space-between;
    max-width: var(--main-max-width);
    margin: 0 auto;

    @media screen and (max-width: 1049px) {
      padding: 0 15px 70px;
    }

    @media screen and (min-width: 1050px) and (max-width: 1279px) {
      padding: 0 70px 100px;
    }

    @media (--desktop-large) {
      padding: 0 0 100px;
    }
  }

  &__process {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    @media (--tablet-l) {
      max-width: 490px;
      width: 100%;
    }

    @media (--desktop-m) {
      max-width: 610px;
    }

    @media screen and (max-width: 899px) {
      margin: 0 auto;
      padding: 0 15px;
    }

    &-message {
      display: none;
      flex-direction: column;
      align-items: center;
      width: 280px;
      height: 575px;
      padding: 43px 17px;
      background-image: url("../img/how-it-works/phone.svg");
      background-repeat: no-repeat;
      @media (--tablet-l) {
        display: flex;
      }

      & > div {
        overflow: hidden;
      }

      img {
        @keyframes firstImageAnimation {
          0% {
            transform: translateY(-130%);
            opacity: 0;
          }
          10% {
            transform: translateY(0);
            opacity: 1;
          }
          20% {
            transform: translateY(0);
          }
          30% {
            transform: translateY(115%);
          }
          90% {
            opacity: 1;
          }
          95% {
            opacity: 0;
          }
          100% {
            transform: translateY(115%);
            opacity: 0;
          }
        }

        @keyframes secondImageAnimation {
          0% {
            transform: translateY(-240%);
            opacity: 0;
          }
          20% {
            transform: translateY(-240%);
            opacity: 0;
          }
          30% {
            transform: translateY(-115%);
            opacity: 1;
          }
          90% {
            opacity: 1;
          }
          95% {
            opacity: 0;
          }
          100% {
            transform: translateY(-115%);
            opacity: 0;
          }
        }

        &:first-child {
          position: relative;
          z-index: 1;
          animation: firstImageAnimation ease-out 5s infinite;
        }

        &:last-child {
          animation: secondImageAnimation ease-out 5s infinite;
          opacity: 0;
        }
      }
    }

    &-text {
      margin-bottom: 45px;
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      line-height: 30px;
      color: var(--color-gray);

      @media screen and (max-width: 899px) {
        max-width: 500px;
      }
    }

    &-form {
      display: flex;
      flex-direction: column;
      max-width: 330px;
      width: 100%;
      margin: 0 auto;

      @media (--tablet-l) {
        margin: 0;
      }

      &-input {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 70px;
        margin-bottom: 20px;
        padding: 0 35px;
        border: 1px solid var(--color-gray-border-input);
        border-radius: 35px;

        &.error {
          border-color: #eb2d44;

          .get-orders__process-form-input-error {
            display: block;
          }
        }

        &.valid {
          .get-orders__process-form-input-valid {
            display: block;
          }
        }

        &-error,
        &-valid {
          display: none;
          width: 20px;
          height: 20px;
        }

        label {
          position: absolute;
          top: -12px;
          left: 30px;
          padding: 2px 5px;
          width: max-content;
          font-family: Roboto;
          font-size: 14px;
          line-height: 16px;
          color: var(--color-dark);
          background-color: var(--color-white);
        }

        input {
          flex: 1;
          padding: 30px 0;
          font-family: Roboto;
          font-size: 18px;
          line-height: 20px;
          color: var(--color-gray);
          background-color: transparent;
          border: none;

          &::placeholder {
            color: #9ea5b8;
          }
        }
      }
    }
  }

  &__title {
    margin-bottom: 25px;
    font-size: 48px;
    line-height: 50px;
    color: var(--color-dark);

    @media (--mobile) {
      font-size: 36px;
      line-height: 1em;
    }

    @media screen and (max-width: 899px) {
      max-width: 500px;
      margin: 0 auto 30px;
    }

    @media (--tablet) {
    }

    @media (--tablet-l) {
      max-width: 800px;
    }

    @media (--desktop-m) {
      margin-right: 50px;
      max-width: 980px;
    }

    @media (--desktop-large) {
      width: 100%;
    }
  }

  &__process-form-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
    font-weight: bold;
    font-size: 16px;
    color: var(--color-dark);
    background-color: var(--color-yellow);
    border: 2px solid var(--color-yellow);
    border-radius: 55px;
    transition: all 0.1s ease-out;
    user-select: none;
    text-decoration: none;

    &:hover {
      background-color: transparent;
    }

    &:disabled {
      background-color: var(--color-yellow);
      pointer-events: none;
    }
  }
}

.businesses {
    padding: 40px 0 0;

    &__title {
        font-family: Codec Pro;
        font-size: 48px;
        line-height: 1.05em;
        color: var(--color-dark);
    
        @media (--mobile) {
          font-size: 35px;
          line-height: 1em;
          width: calc(100% - 70px);
          margin: 100px 35px 0;
        }
    
        @media (--tablet) {
          width: calc(100% - 70px);
          margin: 120px 35px 0;
        }
    
        @media (--tablet-l) {
          width: calc(100% - 100px);
          margin: 150px 50px 0;
        }
    
        @media (--desktop-m) {
          max-width: 980px;
          width: 100%;
          margin: 150px auto 0;
        }
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        font-size: 18px;
        font-family: Roboto;
        line-height: 1.5;
        color: var(--color-black-light);

        li {
          padding: 35px 10px;
          margin: 0 20px 20px 0;
          text-align: center;
          box-sizing: border-box;
          border-radius: 20px;
          background-color: var(--color-gray-light-bg);
          transition: all .2s ease-out;
          cursor: pointer;

          /* &:hover {
            color: var(--color-white);
            background-color: var(--color-black);
          } */
        }
    
        @media (--mobile) {
          height: auto;
          padding: 20px 10px 40px;

          li {
            padding: 20px 10px;
            width: calc(50% - 5px);
            margin-bottom: 10px;

            &:nth-child(2n + 1) {
              margin-right: 10px;
            }
            
            &:nth-child(2n) {
              margin-right: 0;
            }
          }
        }
    
        @media (--tablet) {
          width: calc(100% - 70px);
          margin: 0 35px;
          padding: 40px 0 60px;

          li {
            width: calc(25% - 45px/4);
            margin-right: 15px;
            margin-bottom: 15px;

            &:nth-child(4n) {
              margin-right: 0;
            }
          }
        }
    
        @media (--tablet-l) {
          width: calc(100% - 100px);
          margin: 0 50px;
        }

        @media (--desktop-m) {
          li {
            width: 180px;
            margin-right: 20px;
            margin-bottom: 20px;

            &:nth-child(5n) {
              margin-right: 0;
            }

            &:nth-child(4n) {
              margin-right: 20px;
            }
          }
        }
    
        @media (--desktop-m) {
          max-width: 980px;
          width: 100%;
          margin: 0 auto;
        }
    }
}
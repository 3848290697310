.faq {
  padding: 90px 0 90px;

  @media (--mobile) {
    padding: 85px 0 70px;
  }

  &__title {
    font-size: 48px;
    line-height: 41px;
    color: var(--color-dark);
    max-width: var(--main-max-width);

    @media (--mobile) {
      font-size: 35px;
      line-height: 1em;
      margin: 0 30px 20px;
    }

    @media screen and (min-width: 601px) and (max-width: 1049px) {
      margin: 0 45px 45px;
    }

    @media screen and (min-width: 1050px) and (max-width: 1279px) {
      padding: 0 70px;
    }

    @media (--desktop-m) {
      margin: 0 auto 45px;
    }
  }

  &__item {
    max-width: var(--main-max-width);
    border-bottom: 2px solid var(--color-gray-light);
    cursor: pointer;

    @media (--mobile) {
      width: calc(100% - 60px);
      margin: 0 30px;
    }

    @media screen and (min-width: 601px) and (max-width: 1049px) {
      margin: 0 45px;
    }

    @media (--tablet-l) {
      margin: 0 auto;
    }

    @media screen and (min-width: 1050px) and (max-width: 1279px) {
      width: calc(100% - 140px);
    }

    &:last-child {
      border-bottom: none;
    }

    svg {
      path {
        transition: fill 0.2s ease-out;
      }
    }

    &:hover {
      color: var(--color-gray-dark);

      svg {
        path {
          fill: #3e3e3e;
        }
      }
    }

    &.active {
      & > .faq__info {
        svg {
          transform: rotate(180deg);
        }

        & + .faq__content {
          display: block;

          a {
            color: inherit;
            transition: all 0.1s ease-out;

            &:hover {
              color: var(--color-yellow);
            }
          }
        }
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px 0;

    @media (--mobile) {
      padding: 35px 0 30px;
    }

    @media (--tablet-l) {
      padding: 50px;
    }

    @media (--desktop-m) {
      padding: 50px 0;
    }

    h3 {
      font-size: 36px;
      line-height: 31px;
      color: var(--color-gray-dark);
      transition: all 0.2s ease-out;

      @media (--mobile) {
        margin-right: 20px;
        font-size: 28px;
        line-height: 30px;
      }

      @media (--tablet) {
        margin-right: 20px;
      }
    }

    svg {
      min-width: 11px;
      width: 11px;
    }

    &:hover {
      h3 {
        color: var(--color-dark);
      }
    }
  }

  &__content {
    display: none;
    max-width: 780px;
    width: 100%;
    margin: 10px auto 65px;
    font-family: "Roboto";
    font-size: 20px;
    line-height: 1.75em;
    color: #58585c;
    list-style: disc;

    li {
      padding-left: 60px;
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media (--mobile) {
      font-size: 18px;
      line-height: 30px;
      padding-left: 20px;

      & li {
        padding-left: 20px;
      }
    }
  }
}

.product-screen {
  display: flex;
  flex-direction: column;

  @media (--tablet-m) {
    flex-direction: row;
  }

  @media screen and (min-width: 768px) and (max-width: 1049px) {
    padding-top: 83.5px;
  }
}

.product-screen__message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url("../img/product/product-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 499px) {
    padding: 130px 30px 70px;
  }

  @media screen and (min-width: 500px) and (max-width: 767px) {
    padding: 130px 20vw 70px;
  }

  @media (--tablet-m) {
    padding: 0 7vw;
  }
}

.product-screen__message h1 {
  font-weight: 700;
  font-size: 56px;
  line-height: 120%;
  margin: 0;
  color: var(--color-dark);

  @media screen and (max-width: 1279px) {
    font-size: 44px;
    line-height: 48px;
    margin-bottom: 10px;
  }

  @media (--desktop-large) {
    font-size: 56px;
    max-width: 980px;
  }

  @media (--desktop-xlarge) {
    max-width: 1530px;
    width: calc(100% - 100px);
  }

  @media (min-width: 1630px) {
    width: 100%;
  }
}
.product-screen__message h2 {
  @media screen and (max-width: 1049px) {
    margin-bottom: 25px;
    font-size: 28px;
    line-height: 34px;
  }

  @media (--desktop-m) {
    margin-bottom: 45px;
    font-size: 40px;
    line-height: 120%;
  }
}

.product-screen__actions {
  display: flex;
  align-items: center;

  @media screen and (max-width: 1279px) {
    flex-direction: column;
  }
}

.product-screen__register,
.product-screen__demo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0 40px;
  font-weight: 700;
  font-size: 16px;
  color: var(--color-dark);
  text-decoration: none;
  border-radius: 54px;
  transition: all 0.1s ease-out;
  cursor: pointer;
  white-space: nowrap;

  @media screen and (max-width: 1279px) {
    width: 100%;
    height: 60px;
  }

  @media (--desktop-large) {
    width: fit-content;
    height: 70px;
  }
}

.product-screen__register {
  background-color: var(--color-yellow);
  border: 2px solid transparent;

  @media screen and (max-width: 1279px) {
    margin-bottom: 15px;
  }

  @media (--desktop-large) {
    margin-right: 10px;
  }

  &:hover {
    background-color: transparent;
    border-color: var(--color-yellow);
  }
}

.product-screen__demo {
  background-color: var(--color-white);
  border: 2px solid var(--color-yellow);

  &:hover {
    background-color: var(--color-yellow);
  }
}

.product-screen__call_to_action {
  display: flex;
  flex-direction: row;

  @media (--mobile) {
    flex-direction: column;
  }
}

.product-screen__call_to_action video {
  width: 100%;
  height: 100%;
}

.product-screen__message,
.product-screen__call_to_action {
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  @media (--tablet-m) {
    width: 50%;
    height: 50vw;
  }
}

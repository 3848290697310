.partners {
  position: relative;
  background-color: var(--color-black);
  padding-bottom: 50px;

  #examples {
    position: absolute;
    top: -83.5px;
  }

  &__title {
    max-width: var(--main-max-width);
    margin: 0 auto;
    padding: 40px 0;
    font-size: 48px;
    line-height: 41px;
    color: var(--color-white);

    @media (--mobile) {
      font-size: 35px;
      line-height: 1em;
      padding: 40px 30px;
    }

    @media screen and (min-width: 601px) and (max-width: 1049px) {
      padding: 40px 45px;
    }

    @media screen and (min-width: 1050px) and (max-width: 1279px) {
      padding: 40px 70px;
    }

    @media (--desktop-large) {
      padding: 40px 0;
    }
  }

  &__container {
    display: grid;
    grid-row-gap: 50px;
    max-width: var(--main-max-width);

    @media (--mobile) {
      height: auto;
      padding: 20px 35px 80px;
      grid-template-columns: 1fr 1fr;
    }

    @media (--tablet) {
      width: calc(100% - 70px);
      margin: 0 35px;
      padding: 40px 0;
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (--tablet-m) {
      padding: 40px 0;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media (--tablet-l) {
      width: calc(100% - 100px);
      margin: 0 50px;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    @media screen and (min-width: 1050px) and (max-width: 1279px) {
      padding: 40px 70px;
    }

    @media (--desktop-m) {
      width: 100%;
      margin: 0 auto;
      grid-template-columns: repeat(8, 1fr);
    }

    @media (--desktop-large) {
      padding: 40px 0;
    }
  }

  a {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-out;

    img {
      filter: grayscale(1);
    }

    &:hover {
      &:not(.partners__register) {
        transform: translateY(-5px);
      }
    }

    &:nth-child(1) {
      img {
        width: 80px;
      }
    }

    &:nth-child(2) {
      img {
        width: 80px;
      }
    }

    &:nth-child(3) {
      img {
        width: 80px;
      }
    }

    &:nth-child(4) {
      img {
        width: 80px;
      }
    }

    &:nth-child(5) {
      img {
        width: 80px;
      }
    }

    &:nth-child(6) {
      img {
        width: 80px;
      }
    }

    &:nth-child(7) {
      img {
        width: 80px;
      }
    }

    &:nth-child(8) {
      img {
        width: 80px;
      }
    }

    &:last-of-type:not(.partners__register) {
      margin-right: 0;
    }
  }

  &__register {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 330px;
    margin: 0 auto;
    padding: 0 40px;
    font-weight: 700;
    font-size: 16px;
    color: var(--color-dark);
    text-decoration: none;
    border-radius: 54px;
    transition: all 0.1s ease-out;
    cursor: pointer;
    white-space: nowrap;
    background-color: var(--color-yellow);
    border: 2px solid var(--color-yellow);
  
    &:hover {
      background-color: var(--color-white);
    }

    @media (--mobile) {
      width: calc(100% - 60px);
    }
  
    @media screen and (max-width: 1279px) {
      height: 60px;
    }
  
    @media (--desktop-large) {
      height: 70px;
    }
  }

}

html {
  width: 100%;
  height: 100%;

  @media (--tablet) {
    min-height: 100%;
  }

  scroll-behavior: smooth;
}

body {
  position: relative;
  min-width: 320px;
  padding: 0;
  font-size: 1rem;
  font-family: var(--font-family-base);
  overflow: visible;

  @media (--tablet) {
    min-height: 100%;
  }

  &.pop-up {
    overflow: hidden;
  }
}

main {
  /* max-width: 1280px;
  margin: 0 auto; */
  /* padding-top: 110px; */

  @media (--mobile) {
    /* padding-top: 80px; */
  }
}

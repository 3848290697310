.personal-menu {
  position: relative;
  background: url("../img/personal-menu/personal-menu-bg.webp"),
    linear-gradient(135.02deg, #ffcf72 -9.85%, #ffef9a 95.63%), #9fa3cd;
  border-radius: 70px 70px 0 0;
  margin-bottom: -70px;
  background-size: cover;

  #design {
    position: absolute;
    top: -83.5px;
  }

  @media screen and (max-width: 1049px) {
    padding: 55px 15px 155px;
    border-radius: 50px 50px 0 0;
  }

  @media (--desktop-m) {
    padding: 90px 70px 225px;
  }

  &__title {
    max-width: var(--main-max-width);
    color: var(--color-dark);

    @media screen and (min-width: 601px) and (max-width: 1049px) {
      margin-left: 30px;
    }

    @media (--mobile) {
      margin: 0 15px;
      font-size: 36px;
      line-height: 40px;
    }

    @media (--tablet) {
      font-size: 48px;
      line-height: 110%;
    }

    @media screen and (max-width: 1049px) {
      margin-bottom: 30px;
    }

    @media screen and (min-width: 1050px) and (max-width: 1279px) {
      margin-left: 7vw;
      margin-bottom: 55px;
    }

    @media (--desktop-large) {
      margin: 0 auto 55px;
    }
  }

  &__items {
    display: grid;
    margin: 0 auto;
    background-color: var(--color-white);
    border-radius: 50px;

    @media screen and (min-width: 768px) and (max-width: 1049px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 25px 7%;
    }

    @media screen and (max-width: 1049px) {
      padding: 30px;
    }

    @media (--desktop-m) {
      grid-template-columns: repeat(3, 1fr);
      gap: 25px 7%;
      padding: 40px 45px 50px;
    }

    @media (--desktop-xlarge) {
      max-width: 1300px;
    }
  }

  &__item {
    @media (--tablet-m) {
      border-bottom: 1px solid var(--color-gray-border-input);
    }
    img {
      @media screen and (max-width: 1049px) {
        margin-bottom: 5px;
      }
      @media (--desktop-m) {
        margin-bottom: 10px;
      }
    }

    &-title {
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      color: var(--color-dark);

      @media screen and (max-width: 1049px) {
        margin-bottom: 10px;
      }
      @media (--desktop-m) {
        margin-bottom: 15px;
      }
    }

    &-text {
      font-family: "Roboto";
      font-size: 16px;
      line-height: 25px;
      color: #626262;
      opacity: 0.8;

      @media screen and (max-width: 1049px) {
        margin-bottom: 25px;
      }
      @media (--desktop-m) {
        margin-bottom: 40px;
      }
    }

    &--no-border {
      border: none;

      .personal-menu__item-text {
        margin-bottom: 0;
      }
    }
  }
}

.footer {
  background-color: #fafafa;

  @media (--mobile) {
    padding: 40px 25px 55px;
  }

  @media screen and (min-width: 601px) and (max-width: 1049px) {
    padding: 55px 45px 50px;
  }

  @media (--desktop-m) {
    padding: 55px 0 50px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    max-width: var(--main-max-width);

    @media (--desktop-m) {
      margin: 0 auto;
    }

    @media screen and (min-width: 1050px) and (max-width: 1279px) {
      width: calc(100% - 140px);
    }

    @media (--desktop-large) {
      width: 100%;
    }

    nav {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      max-width: 680px;
      width: 100%;
      margin-bottom: 50px;

      @media (--mobile) {
        grid-template-columns: 1fr;
        margin-bottom: 15px;
      }

      @media (--tablet) {
        grid-template-columns: 1fr 1fr;
      }

      a {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 18px;
        color: var(--color-dark);
        text-decoration: none;
        transition: color 0.1s ease-out;

        &:nth-child(1) {
          @media (--tablet) {
            order: 0;
          }
        }

        &:nth-child(2) {
          @media (--tablet) {
            order: 2;
          }

          @media (--tablet-m) {
            margin-bottom: 0;
          }
        }

        &:nth-child(3) {
          @media (--tablet) {
            order: 1;
          }
        }

        &:nth-child(4) {
          @media (--tablet) {
            order: 3;
          }

          @media (--tablet-m) {
            margin-bottom: 0;
          }
        }

        svg {
          margin-right: 5px;

          path {
            transition: fill 0.25s ease-out;
          }
        }

        @media (--mobile) {
          margin-bottom: 30px;
          justify-content: flex-start;
        }
      }
    }

    p {
      margin-bottom: 30px;
      font-size: 14px;
      line-height: 1.8em;
      color: var(--color-gray-dark);

      @media (--tablet-l) {
        text-align: left;
      }

      a {
        color: var(--color-gray-dark);
        padding-bottom: 2px;
        border-bottom: solid 1px var(--color-gray-dark);
        text-decoration: none;
        transition: color 0.1s ease-out;

        @media (--mobile) {
          display: inline-block;
        }

        &:hover {
          color: var(--color-dark);
          border-color: var(--color-dark);
        }
      }

      svg {
        margin: 0 10px;

        @media (--mobile) {
          display: none;
        }
      }
    }

    &-payment-border {
      width: 100%;
      height: 2px;
      background-color: #efefef;

      @media (--mobile) {
        width: calc(100% + 50px);
        margin-left: -25px;
      }
    }

    &-payment {
      display: grid;
      grid-row-gap: 40px;
      width: 100%;
      padding: 40px 0 55px;

      @media (--mobile) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      @media (--tablet) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      @media (--tablet-m) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }

      @media (--tablet-l) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      }

      li {
        display: flex;
        justify-content: center;
      }

      img {
        width: 60px;
        mix-blend-mode: multiply;
        object-fit: contain;

        &.w67 {
          width: 67px;
        }

        &.w70 {
          width: 70px;
        }

        &.w68 {
          width: 68px;
        }

        &.w84 {
          width: 84px;
        }

        &.w80 {
          width: 80px;
        }

        &.w90 {
          width: 90px;
        }

        &.w110 {
          width: 110px;
        }

      }
    }

    &-links {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;

      li {
        margin: 0 20px 10px 0;
        font-size: 14px;
        line-height: 1.8em;

        a {
          color: var(--color-gray-dark);
          padding-bottom: 2px;
          border-bottom: solid 1px var(--color-gray-dark);
          text-decoration: none;
          transition: color 0.1s ease-out;

          &:hover {
            color: var(--color-dark);
            border-color: var(--color-dark);
          }
        }
      }

      &-delim {
        @media (--mobile) {
          display: none;
        }
      }
    }
  }
}

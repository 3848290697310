.upcoming {
  @media (--mobile) {
    margin-bottom: 70px;
  }

  &__container {
    max-width: 980px;
    margin: 90px 0 70px;

    @media (--mobile) {
      width: calc(100% - 50px);
      margin: 80px 25px 60px;
    }

    @media (--tablet) {
      width: calc(100% - 70px);
      margin: 90px 35px 70px;
    }

    @media (--tablet-l) {
      width: calc(100% - 100px);
      margin: 90px 50px 70px;
    }

    @media (--desktop-m) {
      margin: 90px auto 70px;
    }
  }

  &__title {
    margin-bottom: 20px;
    font-size: 36px;
    line-height: 1em;
    color: var(--color-dark);
  }

  &__item {
    display: inline-flex;
    width: calc(50% - 10px);
    padding: 28px 0 27px;
    font-family: "Roboto";
    font-size: 16px;
    line-height: 1.5em;
    color: var(--color-dark);
    text-decoration: none;
    border-bottom: 2px solid var(--color-gray-light);
    pointer-events: none;

    @media (--mobile) {
      width: 100%;
    }

    &:nth-child(odd) {
      margin-right: 20px;

      @media (--mobile) {
        margin-right: 0;
      }
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &__description {
    margin-bottom: 40px;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-gray-dark);
  }
}
.services {
  @media screen and (max-width: 899px) {
    padding-bottom: 70px;
  }

  @media (--tablet-l) {
    padding-bottom: 95px;
  }

  &__title {
    max-width: var(--main-max-width);
    color: var(--color-white);

    @media (--mobile) {
      font-size: 36px;
      line-height: 1em;
      margin: 0 30px 30px;
    }

    @media (--tablet) {
      font-size: 48px;
      line-height: 41px;
    }

    @media screen and (min-width: 601px) and (max-width: 1049px) {
      margin: 0 45px 30px;
    }

    @media screen and (min-width: 1050px) and (max-width: 1279px) {
      padding-left: 7vw;
      width: calc(100% - 140px);
      margin: 0 auto 55px;
    }

    @media (--desktop-large) {
      width: 100%;
      margin: 0 auto 55px;
    }
  }

  &__items {
    display: flex;
    align-items: center;
    --gap: 0.5rem;
    position: relative;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);

    &--left {
      .services__items-container {
        &--first {
          animation: scroll-left 100s linear infinite;
        }

        &--second {
          position: absolute;
          top: 0;
          left: 0;

          animation: scroll-abs-left 100s linear infinite;
        }
      }
    }

    &--right {
      .services__items-container {
        &--first {
          animation: scroll-right 100s linear infinite;
        }

        &--second {
          position: absolute;
          top: 0;
          left: 0;

          animation: scroll-abs-right 100s linear infinite;
        }
      }
    }

    &-container {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      gap: var(--gap);
      min-width: 100%;
    }

    @keyframes scroll-abs-left {
      from {
        transform: translateX(calc(100% + var(--gap)));
      }
      to {
        transform: translateX(0);
      }
    }

    @keyframes scroll-left {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(calc(-100% - var(--gap)));
      }
    }

    @keyframes scroll-abs-right {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(calc(100% + var(--gap)));
      }
    }

    @keyframes scroll-right {
      from {
        transform: translateX(calc(-100% - var(--gap)));
      }
      to {
        transform: translateX(0);
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-white);
    border-radius: 54px;
    padding: 0 25px;
    margin-bottom: 10px;
    flex-shrink: 0;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 899px) {
      height: 60px;
    }

    @media (--tablet-l) {
      height: 70px;
    }
  }
}

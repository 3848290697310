.text-page {
  max-width: 780px;
  
  @media (--mobile) {
    width: calc(100% - 30px);
    margin: 90px 15px 0;
  }

  @media (--tablet) {
    width: calc(100% - 70px);
    margin: 75px 35px 0;
  }

  @media (--tablet-m) {
    width: calc(100% - 100px);
    margin: 75px 50px 0;
  }

  @media (--tablet-l) {
    width: 100%;
    margin: 75px auto 0;
  }

  a.button {
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    padding: 20px 40px;
    font-weight: 700;
    font-size: 16px;
    color: var(--color-dark);
    background-color: #fcd561;
    border: 2px solid transparent;
    text-decoration: none;
    border-radius: 54px;
    transition: all 0.1s ease-out;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      color: var(--color-dark);
      background-color: #fff;
      border: 2px solid #fcd561;
    }
  }

  h1 {
    margin: 0 0 40px;
    font-size: 72px;
    line-height: 1.1em;
    color: var(--color-dark);

    @media (--mobile) {
      font-size: 48px;
      line-height: 1.05em;
    }
  }

  h2 {
    margin-bottom: 30px;
    font-size: 48px;
    line-height: 1.05em;
    color: var(--color-dark);

    @media (--mobile) {
      font-size: 36px;
      line-height: 1em;
    }
  }

  h3 {
    margin-bottom: 25px;
    font-size: 36px;
    line-height: 1em;
    color: var(--color-dark);

    @media (--mobile) {
      font-size: 28px;
      line-height: 1em;
    }
  }

  h4 {
    margin-bottom: 15px;
    font-size: 28px;
    line-height: 1em;
    color: var(--color-dark);

    @media (--mobile) {
      font-size: 22px;
      line-height: 1em;
    }
  }

  a {
    color: var(--color-dark);
    text-decoration: none;
    padding-bottom: 3px;
    border-bottom: solid 2px var(--color-dark);
    transition: all 0.1s ease-out;

    &:hover {
      border-bottom: solid 2px var(--color-yellow);
    }
  }

  p {
    margin-bottom: 50px;
    font-family: Roboto;
    font-size: 20px;
    line-height: 1.75em;
    color: var(--color-black-light);
  }

  video {
    margin: 0 0 60px;
    max-width: 100%;
    border-radius: 20px;
    box-shadow: 0 5px 50px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;

    @media (--desktop-large) {
      position: relative;
      max-width: 1080px;
      left: calc((780px - 1080px) / 2);
    }
  }

  p.video {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }

  p.video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  blockquote {
    padding: 20px 40px;
    margin: 20px 0px 40px;
    font-family: Roboto;
    font-size: 1.5em;
    line-height: 1.75em;
    color: var(--color-black-light);
    background-color: var(--color-gray-light);
    font-family: 'Codec Pro';
    border-radius: 40px;
  }

  img {
    display: inline;
    width: 100%;

    &.w48 {
      width: 48%;
    }
  }

  ul {
    padding-left: 60px;
    color: var(--color-black-light);
    font-family: Roboto;
    font-size: 20px;
    line-height: 1.75em;
    margin-bottom: 40px;

    li {
      padding: 0 0 20px 30px;
      color: inherit;
      list-style: disc;
    }    
  }

  ol {
    padding-left: 60px;
    color: var(--color-black-light);
    font-family: Roboto;
    font-size: 20px;
    line-height: 1.75em;
    margin-bottom: 40px;

    li {
      padding: 0 0 20px 30px;
      color: inherit;
      list-style: decimal;
    }    
  }

  strong {
    font-weight: bold;
    color: inherit;
  }
}

h1 {
  margin: 75px 25px 0 50px;
  font-size: 72px;
  line-height: 1.4em;
  color: var(--color-dark);

  @media (--mobile) {
    width: calc(100% - 30px);
    margin: 90px 15px 20px;
    font-size: 40px;
    line-height: 45px;
  }

  @media (--desktop-large) {
    max-width: 980px;
  }

  @media (--desktop-xlarge) {
    margin: 75px 50px 0;
  }

  @media (--desktop-xlarge) {
    max-width: 1530px;
    width: calc(100% - 100px);
    margin: 75px 50px 0;
  }

  @media (min-width: 1630px) {
    width: 100%;
    margin: 75px auto 0;
  }
}

.banner {
  position: relative;
  display: flex;
  align-items: flex-end;
  max-width: 780px;
  margin-right: 20px;
  border-radius: 70px;
  overflow: hidden;

  &:before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: '';
    width: 100%;
    height: calc(100% - 90px);
    background: var(--linear-gold);
    border-radius: 70px;
    z-index: -1;
  }

  @media (--mobile) {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-right: 0;
    max-width: inherit;
    width: 100%;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 85%;
      background: var(--linear-gold);
      border-radius: 50px;
      z-index: -1;

    }
  }

  @media (--tablet) {
    max-width: inherit;
    width: 100%;
  }

  @media (--desktop-m) {
    max-width: inherit;
    width: 100%;
  }

  @media (--desktop-large) {
    justify-content: center;
    max-width: calc(100% - 400px);
  }

  & > img {
    &:first-child {
      width: 435px;
      height: 498px;

      @media (--mobile) {
        max-width: 393px;
        width: 100%;
        height: 100%;
        max-height: 450px;
        margin-left: -50px;
        margin-bottom: -10px;
      }

      @media (--tablet) {
        margin-right: auto;
      }

      @media (--desktop-m) {
        margin-left: -70px;
        margin-right: auto;
      }

      @media (--desktop-large) {
        margin-right: 80px;
      }
    }

    &:last-child {
      margin-bottom: 50px;
      width: 285px;
      height: 330px;

      @media (--mobile) {
        display: none;
      }

      @media (--tablet) {
        display: none;
      }

      @media (--tablet-m) {
        display: block;
      }
    }
  }

  &__container {
    display: flex;
    width: calc(100% - 75px);
    margin: 0 25px 0 50px;

    @media (--mobile) {
      width: calc(100% - 30px);
      margin: 0 15px 0 15px;
    }

    @media (--desktop-large) {
      width: calc(100% - 75px);
      margin: 0 25px 0 50px;
    }

    @media (--desktop-xlarge) {
      max-width: 1530px;
      width: calc(100% - 100px);
      margin: 0 50px;
    }

    @media (min-width: 1630px) {
      width: 100%;
      margin: 0 auto;
    }
  }

  &__food-slider {
    max-width: 380px;
    width: 100%;
    height: 380px;
    margin-top: 90px;
    background: #F6F6F7;
    border-radius: 70px;
    cursor: pointer;

    @media (--mobile) {
      display: none;
    }

    @media (--tablet) {
      display: none;
    }

    @media (--desktop-m) {
      display: block;
    }

    .swiper-slide {
      figure {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: contain;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .swiper-pagination-bullet {
      width: 5px;
      height: 5px;
      opacity: 1;
      background-color: var(--color-white);

      &-active {
        background-color: #D1D3D6;
      }
    }

    .swiper-pagination-bullets {
      right: 20px;
    }
  }

  
}

/* .food-slide {
  
  flex: 1;

  img {
    width: 100%;
  }
} */

.alcohol-online-menu,
.gdpr-policy,
.integracia-menu-s-iiko,
.photo-phone,
.resto-site,
.site-menu,
.licence-agreement,
.online-order,
.payment-page,
.qr-codes-page,
.restaurant-marketing,
.restaurants-during-covid {
  padding-top: 91px;
}